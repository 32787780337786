var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "containerManagement-list" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [_c("app-header", { attrs: { title: _vm.title, isShowBack: false } })],
        1
      ),
      _vm.loginType == 1
        ? _c(
            "div",
            {
              staticStyle: {
                "padding-bottom": "30px",
                display: "inline-block",
                "margin-right": "20px"
              }
            },
            [
              _c("span", { staticStyle: { "padding-right": "20px" } }, [
                _vm._v("绑定平台")
              ]),
              _c(
                "el-select",
                {
                  staticStyle: { width: "240px", display: "inline-block" },
                  on: {
                    change: function($event) {
                      _vm.changePlatformType(_vm.bindPlatformType)
                    }
                  },
                  model: {
                    value: _vm.bindPlatformType,
                    callback: function($$v) {
                      _vm.bindPlatformType = $$v
                    },
                    expression: "bindPlatformType"
                  }
                },
                _vm._l(_vm.bindPlatformTypeList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { "padding-bottom": "30px", display: "inline-block" } },
        [
          _c("span", { staticStyle: { "padding-right": "20px" } }, [
            _vm._v("货柜类型")
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "240px", display: "inline-block" },
              on: {
                change: function($event) {
                  _vm.changeContainerType(_vm.containerType)
                }
              },
              model: {
                value: _vm.containerType,
                callback: function($$v) {
                  _vm.containerType = $$v
                },
                expression: "containerType"
              }
            },
            _vm._l(_vm.containerTypeList, function(item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.loginType == 1
        ? _c(
            "div",
            { staticClass: "containerManagement-firmwave" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.goFirmwareManage()
                    }
                  }
                },
                [_vm._v("固件管理")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.bindPlatformType == 1
        ? [
            _vm.containerType == 1
              ? _c("L2K", { attrs: { bindPlatformType: _vm.bindPlatformType } })
              : _vm._e(),
            _vm.containerType == 2
              ? _c("L21", { attrs: { bindPlatformType: _vm.bindPlatformType } })
              : _vm._e(),
            _vm.containerType == 3
              ? _c("L22", { attrs: { bindPlatformType: _vm.bindPlatformType } })
              : _vm._e(),
            _vm.containerType == 4
              ? _c("L21BD", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 5
              ? _c("L21BX", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 6
              ? _c("L22BD", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 7
              ? _c("L22BX", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 8 ||
            _vm.containerType == 10 ||
            _vm.containerType == 16 ||
            _vm.containerType == 17 ||
            _vm.containerType == 18 ||
            _vm.containerType == 2001 ||
            _vm.containerType == 2004 ||
            _vm.containerType == 2005 ||
            _vm.containerType == 2006 ||
            _vm.containerType == 2007 ||
            _vm.containerType == 2008 ||
            _vm.containerType == 2009 ||
            _vm.containerType == 2010 ||
            _vm.containerType == 2011 ||
            _vm.containerType == 2012 ||
            _vm.containerType == 2013 ||
            _vm.containerType == 4049
              ? _c("L25", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e(),
            _vm.showL25AFlage
              ? _c("L25A", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e(),
            _vm.containerType == 19
              ? _c("L25AB", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e(),
            _vm.containerType == 20 ||
            _vm.containerType == 21 ||
            _vm.containerType == 2014
              ? _c("T160", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm.bindPlatformType == 2
        ? [
            _vm.containerType == 1
              ? _c("NEWL2K", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 2
              ? _c("NEWL21", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 3
              ? _c("NEWL22", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 4
              ? _c("NEWL21BD", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 5
              ? _c("NEWL21BX", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 6
              ? _c("NEWL22BD", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 7
              ? _c("NEWL22BX", {
                  attrs: { bindPlatformType: _vm.bindPlatformType }
                })
              : _vm._e(),
            _vm.containerType == 8 ||
            _vm.containerType == 10 ||
            _vm.containerType == 16 ||
            _vm.containerType == 17 ||
            _vm.containerType == 18 ||
            _vm.containerType == 2001 ||
            _vm.containerType == 2004 ||
            _vm.containerType == 2005 ||
            _vm.containerType == 2006 ||
            _vm.containerType == 2007 ||
            _vm.containerType == 2008 ||
            _vm.containerType == 2009 ||
            _vm.containerType == 2010 ||
            _vm.containerType == 2011 ||
            _vm.containerType == 2012 ||
            _vm.containerType == 2013 ||
            _vm.containerType == 4049
              ? _c("NEWL25", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e(),
            _vm.showL25AFlage
              ? _c("NEWL25A", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e(),
            _vm.containerType == 19
              ? _c("NEWL25AB", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e(),
            _vm.containerType == 20 ||
            _vm.containerType == 21 ||
            _vm.containerType == 2014
              ? _c("NEWT160", {
                  attrs: {
                    containerType: _vm.containerType,
                    bindPlatformType: _vm.bindPlatformType
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }